import { Box, Flex } from '@cfacorp/cowponents';
import styled from 'styled-components';

import { formatModifier, formatModifierPrice } from 'src/util/format';

type ComboItem = {
  name?: string;
  tag?: string;
  itemTag?: string;
  displayQuantity: number;
  price?: number;
};

type Modifier = {
  action: string;
  modifierType: string;
  name: string;
  tag?: string;
  itemNumber?: string;
  displayQuantity?: number;
  quantity?: number;
  price?: number;
};

type Item = {
  specialInstructions?: string;
  modifiers?: Modifier[];
  selectedSide?: ComboItem;
  selectedDessert?: ComboItem;
};

interface Props {
  item: Item;
}

const OrderSummaryModifiers: React.FC<Props> = ({ item }) => {
  const { specialInstructions = '', selectedDessert, selectedSide } = item;
  const { modifiers } = item;

  const renderComboItem = (comboItem?: ComboItem) =>
    comboItem &&
    comboItem.name && (
      <Flex
        className="modifier-group selected-combo-item"
        key={`${comboItem.tag ?? comboItem.itemTag}-order-summary`}
      >
        <Box ml="4.1%" textAlign="left" width={6.5 / 12}>
          {comboItem.name}
        </Box>
        <Box textAlign="center" width={3.5 / 12}>
          {comboItem.displayQuantity}
        </Box>
        <Box textAlign="right" width={1.5 / 12}>
          {formatModifierPrice(comboItem.price)}
        </Box>
      </Flex>
    );

  return (
    <StyledOrderSummaryModifiers>
      {modifiers &&
        modifiers.map((mod) => {
          //this is added because api gives us discount with modifiers which have the same tag and it duplicates modifier keys
          let modTag = mod?.tag;
          if (modTag) {
            const checkTag = modTag.slice(0, 5);
            if (checkTag === 'Local') {
              modTag = mod.itemNumber;
            }
          }
          return (
            //mod.itemNumber is added in case when we have tax items which modifiers don't have tags
            <Flex className="modifier-group" key={`${modTag ?? mod.itemNumber}-order-summary`}>
              <Box ml="4.1%" textAlign="left" width={6.5 / 12}>
                {formatModifier(mod)}
              </Box>
              <Box textAlign="center" width={3.5 / 12}>
                {mod.displayQuantity || mod.quantity}
              </Box>
              <Box textAlign="right" width={1.5 / 12}>
                {formatModifierPrice(mod.price)}
              </Box>
            </Flex>
          );
        })}
      {renderComboItem(selectedSide)}
      {renderComboItem(selectedDessert)}
      <Box className="specials" color="primary" ml="4.1%">
        {specialInstructions}
      </Box>
    </StyledOrderSummaryModifiers>
  );
};

const StyledOrderSummaryModifiers = styled.div`
  font: ${(props) => props.theme.regularTextFont};

  & .modifier-group,
  .selected-combo-item,
  .specials {
    line-height: 27px;
  }
`;

export default OrderSummaryModifiers;
