import { Box, Flex } from '@cfacorp/cowponents';
import styled from 'styled-components';
import { v4 } from 'uuid';

import { OrderLineItem } from 'src/types/order';
import { formatItemName } from 'src/util/format';

interface Props {
  lineItems: OrderLineItem[];
}

const PastOrderSummary: React.FC<Props> = ({ lineItems }) => {
  return (
    <StyledPastOrderSummary data-testid="past-order-summary">
      <Flex>
        <Box fontWeight="bold" mb="5px" width={7 / 12}>
          Item name
        </Box>
        <Box fontWeight="bold" mb="5px" textAlign="center" width={5 / 12}>
          Quantity
        </Box>
      </Flex>
      {lineItems.map((item) => (
        <Box key={item.itemId || v4()} mb="5px">
          <Flex lineHeight="27px">
            <Box data-cy={`${item.itemTag}-name`} data-testid="item-name" textAlign="left" width={7 / 12}>
              {formatItemName(item.name)}
            </Box>
            <Box
              data-cy={`${item.itemTag}-quantity`}
              data-testid="item-quantity"
              textAlign="center"
              width={3.5 / 12}
            >
              {item.quantity}
            </Box>
          </Flex>
        </Box>
      ))}
    </StyledPastOrderSummary>
  );
};

const StyledPastOrderSummary = styled.div`
  font: ${(props) => props.theme.regularTextFont};
  padding: 10px;

  div {
    font: ${(props) => props.theme.smallTextFont};
    line-height: 20px;
  }
`;

export default PastOrderSummary;
