import { type Reducer, type UnknownAction } from '@reduxjs/toolkit';
import { clone, contains } from 'ramda';
import { v4 } from 'uuid';

import ooeConstants from '../constants';
import type { MenuItem } from '../types/menu';
import { actions as orderActions, types as orderTypes } from '../reducers/order';
import { convertLineItemsToCartItems } from './utils';

export const types = {
  ADD_TO_CART: '[Cart] add',
  UPDATE_QUANTITY: '[Cart] Update quantity',
  DELETE_ITEM: '[Cart] Delete item',
  ADD_MODIFIER: '[Cart] Add modifier',
  UPDATE_MODIFIER_QUANTITY: '[Cart] Update modifier quantity',
  UPDATE_SPECIAL_INSTRUCTIONS: '[Cart] Update special instructions',
  UPDATE_SIDE_ITEM: '[Cart] Update side item',
  UPDATE_DESSERT_ITEM: '[Cart] Update dessert item',
  REMOVE_NON_EXISTENT_ITEMS: '[Cart] remove items that are no longer in the menu',
  MAKE_PROMO_FREE: '[Cart] Promo free item',
  REMOVE_PROMO_FREE: '[Cart] Remove promo free from item',
};

export const actions = {
  addToCart: (item: MenuItem) => ({
    type: types.ADD_TO_CART,
    item,
    tag: item.tag,
  }),
  updateQuantity: (id: string, quantity: number) => ({
    type: types.UPDATE_QUANTITY,
    quantity,
    id,
  }),
  deleteItem: (id: string) => ({ type: types.DELETE_ITEM, id }),
  addModifier: (
    id: string,
    modifier: MenuItem & { quantity?: number },
    { comboTag }: Pick<MenuItem, 'comboTag'> = {},
  ) => ({
    type: types.ADD_MODIFIER,
    modifier,
    comboTag,
    modifierTag: modifier.tag,
    id,
  }),
  updateModifierQuantity: (
    id: string,
    modifier: CartItemModifier,
    quantity: number,
    { comboTag }: Pick<CartItemModifier, 'comboTag'> = {},
  ) => ({
    type: types.UPDATE_MODIFIER_QUANTITY,
    modifier,
    quantity,
    comboTag,
    modifierTag: modifier.tag,
    id,
  }),
  updateSpecialInstructions: (id: string, specialInstructions: string) => ({
    type: types.UPDATE_SPECIAL_INSTRUCTIONS,
    specialInstructions,
    id,
  }),
  updateSideItem: (id: string, side: MenuItem) => ({
    type: types.UPDATE_SIDE_ITEM,
    side,
    id,
  }),
  updateDessertItem: (id: string, dessert: MenuItem) => ({
    type: types.UPDATE_DESSERT_ITEM,
    dessert,
    id,
  }),
  removeNonExistentItems: (tags: string[]) => ({
    type: types.REMOVE_NON_EXISTENT_ITEMS,
    tags,
  }),
  makePromoFree: (id: string) => ({ type: types.MAKE_PROMO_FREE, id }),
  removePromoFree: (id: string) => ({ type: types.REMOVE_PROMO_FREE, id }),
};

const initialState: CartState = [];

function getIndex(newState: CartState, id: string) {
  return newState.findIndex((item) => item.id === id);
}

const reducer: Reducer<CartState, UnknownAction> = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_TO_CART: {
      const { item } = action as ReturnType<(typeof actions)['addToCart']>;
      const { tag, sideItems, dessertItems } = item;
      const selectedSideTag = ((sideItems && sideItems[0]) || {}).tag;
      const selectedDessertTag = ((dessertItems && dessertItems[0]) || {}).tag;
      return [
        ...state,
        {
          tag,
          selectedSideTag,
          selectedDessertTag,
          quantity: 1,
          modifiers: {},
          specialInstructions: '',
          id: v4(),
        },
      ];
    }

    case types.UPDATE_QUANTITY: {
      const { id, quantity } = action as ReturnType<(typeof actions)['updateQuantity']>;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      if (+quantity === 0) {
        newState.splice(idx, 1);
        return newState;
      }
      newState[idx] = {
        ...newState[idx],
        quantity: +quantity,
      };
      return newState;
    }

    case types.UPDATE_SIDE_ITEM: {
      const { side, id } = action as ReturnType<(typeof actions)['updateSideItem']>;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      newState[idx] = {
        ...newState[idx],
        selectedSideTag: side.tag,
      };
      return newState;
    }

    case types.UPDATE_DESSERT_ITEM: {
      const { dessert, id } = action as ReturnType<(typeof actions)['updateDessertItem']>;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      newState[idx] = {
        ...newState[idx],
        selectedDessertTag: dessert.tag,
      };
      return newState;
    }

    case types.UPDATE_MODIFIER_QUANTITY:
    case types.ADD_MODIFIER: {
      const { modifier, comboTag, id } = action as ReturnType<(typeof actions)['addModifier']>;
      let modifiers: Record<string, CartItemModifier> | undefined;
      let setQuantity = (action as ReturnType<(typeof actions)['updateModifierQuantity']>).quantity;
      const { tag } = modifier;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      const existingItem = newState[idx];
      const toggleable = contains(tag, ooeConstants.TOGGLEABLE_ITEM_TAGS);
      // multigrain bun can only have quantity of 1 or 0
      if (toggleable) {
        setQuantity = !modifier.quantity || modifier.quantity === 0 ? 1 : 0;
      }

      // prevent adding modifiers to items that don't exist
      if (!existingItem) {
        return newState;
      }
      const existingModifier = (existingItem.modifiers && existingItem.modifiers[tag]) || { quantity: 0 };

      if (!setQuantity && setQuantity !== 0) {
        setQuantity = +existingModifier.quantity + 1;
      }

      if (setQuantity === 0) {
        modifiers = { ...existingItem.modifiers };
        delete modifiers[tag];
      } else {
        modifiers = {
          ...existingItem.modifiers,
          [tag]: {
            tag,
            quantity: setQuantity,
            comboTag,
          },
        };
      }
      newState[idx] = { ...existingItem, modifiers };
      return newState;
    }

    case types.UPDATE_SPECIAL_INSTRUCTIONS: {
      const { specialInstructions, id } = action as ReturnType<(typeof actions)['updateSpecialInstructions']>;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      newState[idx] = {
        ...newState[idx],
        specialInstructions,
      };
      return newState;
    }

    case orderTypes.INITIATE_EDIT_ORDER: {
      const {
        order: { lineItems },
      } = action as ReturnType<(typeof orderActions)['initiateEditOrder']>;
      return convertLineItemsToCartItems(lineItems);
    }

    case types.MAKE_PROMO_FREE: {
      const { id } = action as ReturnType<(typeof actions)['makePromoFree']>;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      newState[idx] = {
        ...newState[idx],
        promoFree: true,
      };
      return newState;
    }
    case types.REMOVE_PROMO_FREE: {
      const { id } = action as ReturnType<(typeof actions)['removePromoFree']>;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      newState[idx] = {
        ...newState[idx],
        promoFree: false,
      };
      return newState;
    }

    case types.DELETE_ITEM: {
      const { id } = action as ReturnType<(typeof actions)['deleteItem']>;
      return state.filter((item) => item.id !== id);
    }

    case types.REMOVE_NON_EXISTENT_ITEMS: {
      const { tags } = action as ReturnType<(typeof actions)['removeNonExistentItems']>;
      return state.filter((item) => !contains(item.tag, tags));
    }

    case orderTypes.EXIT_EDIT_ORDER:
    case orderTypes.SUBMIT_ORDER_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reducer;
