import { Box, Flex } from '@cfacorp/cowponents';

import { selectCartWithPrices } from 'src/reducers';
import { formatModifier, formatModifierPrice } from '../../util/format';

interface ModifiersProps {
  item: ReturnType<typeof selectCartWithPrices>[number];
}

const Modifiers: React.FC<ModifiersProps> = ({ item }) => {
  const { modifiers, selectedSide: side, selectedDessert: dessert } = item;

  const renderComboItem = (
    comboItem:
      | { name?: string; price: number; displayQuantity: number; tag: string; itemPrice: number }
      | undefined,
  ) =>
    comboItem &&
    comboItem.name && (
      <Flex alignItems="center" key={comboItem.tag} lineHeight="25px">
        <Box textAlign="center" width="50px">
          {comboItem.displayQuantity}
        </Box>
        <Box m="0 20px" width="46%">
          {comboItem.name}
        </Box>
        <Box width="30%">{formatModifierPrice(comboItem.price)}</Box>
      </Flex>
    );

  return (
    <div>
      {modifiers &&
        modifiers.map((mod) => (
          <Flex alignItems="center" key={mod.tag} lineHeight="25px">
            <Box textAlign="center" width="50px">
              {mod.displayQuantity}
            </Box>
            <Box m="0 20px" width="46%">
              {formatModifier(mod)}
            </Box>
            <Box width="30%">{formatModifierPrice(mod.price)}</Box>
          </Flex>
        ))}
      {renderComboItem(side)}
      {renderComboItem(dessert)}
      <Box color="primary" lineHeight="25px" ml="70px">
        {item.specialInstructions || ''}
      </Box>
    </div>
  );
};

export default Modifiers;
