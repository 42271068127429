import { Box, Flex } from '@cfacorp/cowponents';
import styled from 'styled-components';
import { v4 } from 'uuid';

import EmptyCart from 'src/components/EmptyCart';
import OrderSummaryModifiers from 'src/components/OrderSummaryModifiers';
import { formatItemName, formatPrice } from 'src/util/format';

type ComboItem = {
  displayQuantity: number;
};

type Item = {
  tag: string;
  id?: string;
  name?: string;
  quantity: number;
  promoFree?: boolean;
  price: number;
  selectedDessert?: ComboItem;
  selectedSide?: ComboItem;
};

interface Props {
  items: Item[];
}

const OrderSummary: React.FC<Props> = ({ items }) => {
  return (
    <StyledOrderSummary>
      {items.length === 0 ? (
        <EmptyCart message="No items in cart" />
      ) : (
        <>
          <Flex>
            <Box fontWeight="bold" mb="5px" width={7 / 12}>
              Item name
            </Box>
            <Box fontWeight="bold" mb="5px" textAlign="center" width={3.5 / 12}>
              Quantity
            </Box>
            <Box fontWeight="bold" mb="5px" textAlign="right" width={1.5 / 12}>
              Price
            </Box>
          </Flex>
          {items.map((item) => (
            <Box key={item.id || v4()} mb="5px">
              <Flex lineHeight="27px">
                <Box data-cy={`${item.tag}-name`} data-testid="item-name" textAlign="left" width={7 / 12}>
                  {formatItemName(item.name)}
                </Box>
                <Box
                  data-cy={`${item.tag}-quantity`}
                  data-testid="item-quantity"
                  textAlign="center"
                  width={3.5 / 12}
                >
                  {item.quantity}
                </Box>
                <Box
                  data-cy={`${item.tag}-price`}
                  data-testid="item-price"
                  textAlign="right"
                  width={1.5 / 12}
                >
                  {item.promoFree ? 'Promo Free' : formatPrice(item.price)}
                </Box>
              </Flex>
              <OrderSummaryModifiers item={item} />
            </Box>
          ))}
        </>
      )}
    </StyledOrderSummary>
  );
};

const StyledOrderSummary = styled.div`
  font: ${(props) => props.theme.regularTextFont};

  & .empty-cart {
    margin: 0;
    padding-bottom: 0;
  }
`;

export default OrderSummary;
