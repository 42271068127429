import React from 'react';
import { useSelector } from 'react-redux';
import Section from '../../../components/Section';
import SectionHeader from '../../../components/SectionHeader';

import { selectPromoFreeActive } from '../../../cart/selectors';
import OrderSummary from '../../../components/OrderSummary';
import Totals from '../../../components/Totals/Totals';
import { selectCartWithPrices, selectTaxAndTotal } from '../../../reducers';
import {
  selectDeliveryTip,
  selectDeliveryTipLoading,
  selectFormattedSubTotalAmount,
  selectFormattedTaxAmount,
  selectOrderIsLoading,
  selectTotalAmount,
} from '../../../reducers/order';
import { formatPrice } from '../../../util/format';
import { selectDeliveryTipIsDisplayed } from '../../selectors';
import EventSummary from '../EventSummary';

interface Props {
  isInvoicePage?: boolean;
}

const OrderInformation: React.FC<Props> = ({ isInvoicePage = false }) => {
  const cartItems = useSelector(selectCartWithPrices);
  const subTotal = useSelector(selectFormattedSubTotalAmount);
  const taxAndTotal = useSelector(selectTaxAndTotal);
  const orderLoading = useSelector(selectOrderIsLoading);
  const deliveryTip = useSelector(selectDeliveryTip);
  const deliveryTipIsDisplayed = useSelector(selectDeliveryTipIsDisplayed);
  const deliveryTipLoading = useSelector(selectDeliveryTipLoading);
  const promoFreeActive = useSelector(selectPromoFreeActive);
  const taxAmount = useSelector(selectFormattedTaxAmount);
  const totalAmount = useSelector(selectTotalAmount);

  const totalAmountPrice = totalAmount !== 0 ? formatPrice(totalAmount) : taxAndTotal;

  return (
    <>
      <Section>
        <SectionHeader>Event Summary</SectionHeader>
        <EventSummary isInvoicePage={isInvoicePage} />
      </Section>
      <Section>
        <SectionHeader>Order Summary</SectionHeader>
        <OrderSummary items={cartItems} />
        <Totals
          deliveryTip={deliveryTip}
          deliveryTipIsDisplayed={deliveryTipIsDisplayed}
          deliveryTipLoading={deliveryTipLoading}
          isLoading={orderLoading}
          promoFreeActive={promoFreeActive}
          subTotal={subTotal}
          taxAmount={taxAmount}
          taxAndTotal={totalAmountPrice}
        />
      </Section>
    </>
  );
};

export default OrderInformation;
