import { Button } from '@cfa/react-components';

import Section from 'src/components/Section';
import SectionHeader from 'src/components/SectionHeader';

const FullScreenError: React.FC = () => {
  const setLocationToOrigin = () => {
    window.location.href = window.location.origin;
  };

  return (
    <Section>
      <SectionHeader>Oops!</SectionHeader>
      <p>Your session is expiring. Please refresh to login again.</p>
      <Button onClick={setLocationToOrigin}>Refresh</Button>
    </Section>
  );
};

export default FullScreenError;
