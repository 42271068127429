import { Box, Flex } from '@cfacorp/cowponents';
import styled from 'styled-components';

import ooeConstants from '../../constants';

interface NavigationProps {
  className?: string;
  leftDesktop?: JSX.Element;
  leftMobile?: JSX.Element;
  rightDesktop?: JSX.Element;
  rightMobile?: JSX.Element;
  pathname?: string;
  children: React.ReactNode;
}

export const NavigationComponent: React.FC<NavigationProps> = ({
  className,
  children,
  leftMobile,
  leftDesktop,
  rightMobile,
  rightDesktop,
  pathname = '/',
}) => {
  const title = ooeConstants.GET_TITLE[pathname as keyof typeof ooeConstants.GET_TITLE];
  return (
    <div className={className}>
      <Flex className="mobile-header">
        <Box width={2.5 / 12}>{leftMobile}</Box>
        <Box textAlign="center" width={7 / 12}>
          <h2 className="title">{title}</h2>
        </Box>
        <Box width={2.5 / 12}>{rightMobile}</Box>
      </Flex>
      <div className="desktop-navigation">
        <Box width={[0, 0, 1 / 12, 1 / 6]}>{leftDesktop}</Box>
        <Box className="desktop-center" width={[1 / 2, 1 / 2, 1 / 2, 5 / 12]}>
          {children}
        </Box>
        <Box className="desktop-right" width={1 / 2}>
          {rightDesktop}
        </Box>
      </div>
      <div className="mobile-navigation">{children}</div>
    </div>
  );
};

const StyledNavigation = styled(NavigationComponent)`
  position: sticky;
  top: 0;
  z-index: 1249;

  & .mobile-header,
  .desktop-navigation,
  .mobile-navigation {
    height: 75px;
    z-index: 1000;
    font-family: ${(props) => props.theme.fontFamily};
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.background};
    width: 100%;
  }

  & .mobile-header {
    display: none;

    @media (max-width: ${(props) => props.theme.phone}) {
      & .title {
        font-size: 19px;
        margin-top: 0;
        color: ${(props) => props.theme.colors.text};
      }

      display: flex;
      align-items: center;
    }
  }

  & .mobile-navigation {
    display: none;

    @media (max-width: ${(props) => props.theme.phone}) {
      display: block;
      position: fixed;
      left: 0;
      bottom: 0;
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  & .desktop-navigation {
    display: flex;
    align-items: center;

    @media (max-width: ${(props) => props.theme.phone}) {
      display: none;
    }
  }

  & .desktop-right {
    text-align: right;
  }

  & .desktop-center {
    height: 100%;
  }

  & svg {
    fill: ${(props) => props.theme.colors.primary};
  }

  & svg:hover {
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export default StyledNavigation;
